import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'


export default function Rain() {
  return (
    <>
    <Layout>

      <SEO 
        title="Rain"
        description="Rain sound on loop and an endless stream of lofi hip hop. No excuses now, get to work."
      />

      <div className="page-heading-box">
        <h1>RAIN</h1>
      </div>
    </Layout>
    </>
  )
}